<template>
  <div class="app">
  <!-- 根据后台数据，自动生成课消记录列表 -->
  <nut-cell-group v-for="(item, index) in classdeleteList" :key="index">
    <nut-cell title="课消时间" :sub-title=item.time></nut-cell>
    <!-- 课消来源 -->
    <nut-cell title="课消来源" :desc="item.source">
      <template #icon>
      <Search />
     </template>
    </nut-cell>
    <nut-cell title="课程名称" :desc="item.name">
      <template #icon>
      <Star />
     </template>
    </nut-cell>
    <!-- 课消类型 -->
    <nut-cell title="课消类型" :desc="item.type">
      <template #icon>
      <Category />
     </template>
    </nut-cell>
    <nut-cell title="消耗课时" :desc="item.xiaohao">
      <template #icon>
      <Checked />
     </template>
    </nut-cell>
    <nut-cell title="扣课订单" :desc="item.order">
      <template #icon>
      <Order />
     </template>
    </nut-cell>
    <!-- 上课备注 -->
    <nut-cell title="上课备注" :desc="item.remark">
      <template #icon>
      <Message />
     </template>
    </nut-cell>
     <!-- 上课学生 -->
     <nut-cell :title="item.student" >
      <template #icon>
      <My2 />
     </template>
    </nut-cell>
  </nut-cell-group>
</div>
</template>

<script>
import { Search, Star, Checked, Message,Category,Order,My2 } from '@nutui/icons-vue';
export default {
  components: {
    Search, Star, Checked, Message, Category,Order,My2
  },
  data() {
    return {
      classdeleteList: [
        {
          time: '2021年9月1日（星期日）15：00~16：00',
          source: '签到',
          name: '钢琴课',
          type: '按课时',
          xiaohao: '3课时',
          order: '20210901',
          remark: '补课',
          student: '张三'
        },
        {
          time: '2021年9月2日',
          source: '签到',
          name: '钢琴课',
          type: '按课时',
          xiaohao: '1课时',
          order: '20210902',
          remark: '无',
          student: '张三'
        },
        {
          time: '2021年9月3日',
          source: '签到',
          name: '钢琴课',
          type: '按课时',
          xiaohao: '1课时',
          order: '20210903',
          remark: '无',
          student: '张三'
        }
      ]
    }
  }
}
</script>

<style scoped>
.app {
  background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
  height: 100%;
  background-attachment:fixed;
}
</style>